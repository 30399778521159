<template>
  <div>
    <div class="d-flex flex-nowrap pb-2 mb-2" style="overflow-y: auto">
      <div
        v-for="(item, index) in tabs"
        :key="item.status"
        style="min-width: 200px; width: 19%"
        class="mx-2"
      >
        <v-btn
          block
          :color="index == tab ? 'primary' : 'defalut'"
          @click="onTabChange(index)"
        >
          <img :src="item.img" alt="" style="width: 25px; margin-right: 15px" />
          {{ item.text }}
          <v-chip class="ma-2" small v-if="[1].includes(index)">
            {{ item.count }}
          </v-chip>
        </v-btn>
      </div>
    </div>

    <Check v-if="tab == 0" @success="onSuccess" />
    <WaitProcess v-if="tab == 1" @getCount="getCount" />
    <Changed v-if="tab == 2" />
    <History v-if="tab == 3" />
    <S1Book v-if="tab == 4" />
    <GoodsNoInvoice v-if="tab == 5" />
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
export default {
  name: "Index",
  components: {
    Check: () => import("@/components/invoice/Check"),
    WaitProcess: () => import("@/components/invoice/WaitProcess"),
    Changed: () => import("@/components/invoice/Changed"),
    History: () => import("@/components/invoice/History"),
    S1Book: () => import("@/components/invoice/S1Book"),
    GoodsNoInvoice: () => import("@/components/invoice/GoodsNoInvoice"),
  },
  data: () => ({
    tabs: [
      {
        text: "Kiểm tra income",
        img: require("@/assets/internal_requests/1_Cho_xu_Ly.png"),
      },
      {
        text: "Cần xử lý",
        img: require("@/assets/internal_requests/2_Dang_xu_ly.png"),
        count: 0,
      },
      {
        text: "Hóa đơn điều chỉnh",
        img: require("@/assets/internal_requests/3_Cho_xac_nhan.png"),
        count: 0,
      },
      {
        text: "Danh sách hóa đơn",
        img: require("@/assets/internal_requests/4_Da_hoan_thanh.png"),
      },
      {
        text: "Sổ phụ",
        img: require("@/assets/internal_requests/4_Da_hoan_thanh.png"),
      },
      {
        text: "HH không xuất hóa đơn",
        img: require("@/assets/internal_requests/5_ngung_xu_ly.png"),
      },
    ],
    tab: 0,
    page: 1,
    counts: {},
  }),
  mounted() {
    this.getCount();
  },
  methods: {
    onTabChange(index) {
      this.tab = index;
    },
    getCount() {
      httpClient.post("/invoice-get-count").then(({ data }) => {
        const tabs = [...this.tabs];
        tabs[1].count = data.count_wait || 0;
        tabs[2].count = data.count_adjust || 0;
        this.tabs = [...tabs];
      });
    },
    onSuccess() {
      this.getCount();
    },
  },
};
</script>
